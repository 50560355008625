import way from '../../api/encapsulation'

const publicMethod = {
  timestampReturnDate: way.timestampReturnDate,
  // 子路由调用父路由方法
  // toUseLastRouterWay: way.toUseLastRouterWay,
  // 招生专业数据(批次,层次)
  // getStuMajorEvent(obj) {
  //   if (this.majorNameData.length != 0) return;
  //   let formType = obj.formName ? obj.formName : 'formInline'
  //   let method = obj.method ? obj.method : 'majorAdmission'
  //   let batch = this[formType].batch || this[formType].admissionBatch
  //   let eduCategory = this[formType].eduCategory;
  //   let isBool = eduCategory !== null && eduCategory !== '' && eduCategory !== undefined;
  //   if (!batch) {
  //     this.Warn("请先选择批次");
  //     return;
  //   }
  //   if (!isBool) {
  //     this.Warn("请先选择层次");
  //     return;
  //   }
  //   this.$request[method]({ batch, eduCategory }).then((res) => {
  //     if (res.data.code == 0) {
  //       this.majorNameData = res.data.data;
  //     }
  //   });
  // },
  // 专业数据(租户id,层次)
  getMajorDataEvent(form) {
    if (this.majorNameData.length != 0) return;
    let formType = form
    if (typeof formType != 'string') formType = 'formInline'
    let tenantId = JSON.parse(sessionStorage.getItem("loginData")).user_info.tenantId
    let eduCategory = this[formType].eduCategory;
    let isBool = eduCategory !== null && eduCategory !== '' && eduCategory !== undefined;
    if (!isBool) {
      this.Warn("请先选择层次");
      return;
    }
    this.$request.getScStudentMajorData({ tenantId, eduCategory }).then((res) => {
      if (res.data.code == 0) {
        this.majorNameData = res.data.data;
      }
    });
  },
  // 专业模糊查询(租户id,批次,层次)
  getScStudentMajorEvent(form) {
    if (this.majorNameData.length != 0) return;
    let formType = form
    if (typeof formType != 'string') formType = 'formInline'
    let tenantId = this.$route.query.tenantId || this[formType].tenantId
    let eduCategory = this[formType].eduCategory
    let isBool = eduCategory !== null && eduCategory !== '' && eduCategory !== undefined;
    let batch = this[formType].batch || this[formType].admissionBatch
    if (!batch) {
      this.selectThirdEvent()
      this.Warn("请先选择批次");
      return;
    } else if(!isBool) {
      this.Warn('请选择层次')
      return;
    }
    let obj = {
      tenantId,
      eduCategory,
      batch
    };
    this.$request.getMajorList(obj).then((res) => {
      if (res.data.code == 0) {
        this.majorNameData = res.data.data;
      }
    });
  },
  // 获取入学批次数据
  getGoSchoolData() {
    let obj = {
      type: "admission_batch_type",
    };
    this.$request.getGoSchoolOld(obj, res => {
      if (res.code == 0) {
        this.schoolBatchData = res.data;
      }
    });
  },
  // 获取招生批次数据
  // getBatchAdmissionData() {
  //   this.$request.batchAdmission().then((res) => {
  //     if (res.data.code == 0) {
  //       this.schoolBatchData = res.data.data;
  //     }
  //   });
  // },
  // 获取教学点下拉框
  getStationDownBoxData() {
    this.$request.getTheDropDownBox().then((res) => {
      if (res.data.code == 0) {
        this.correspondenceStation = res.data.data;
      }
    });
  },
  // 获取班级数据
  // classListData() {
  //   this.$request.classList().then((res) => {
  //     if (res.data.code == 0) {
  //       this.classData = res.data.data;
  //     }
  //   });
  // },
  // 获取学期下拉
  // getsemesterDownList(obj = {}) {
  //   this.$request.semesterDown(obj).then((res) => {
  //     if (res.data.code == 0) {
  //       this.semesterData = res.data.data;
  //     }
  //   });
  // },
  // 获取课程下拉数据
  // getClassListData() {
  //   this.$request
  //     .getClassList({ examId: this.$route.query.id })
  //     .then((res) => {
  //       if (res.data.code == 0) {
  //         this.classList = res.data.data;
  //       }
  //     });
  // },
  // 获取省份
  getProviceEvent() {
    this.$request.getProvice({}, res => {
      if (res.code == 0) {
        this.provinceData = res.data;
      }
    });
  },
}

export default publicMethod;