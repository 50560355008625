<template>
  <div class="addResource">
    <div class="search-form">
      <el-form
        :inline="true"
        :model="resForm"
        class="demo-form"
        label-width="80px"
      >
        <el-form-item label="批次">
          <el-select
            v-model="resForm.batch"
            @change="selectThirdEvent"
            placeholder="请选择批次"
            filterable
            clearable
          >
            <el-option
              v-for="item in schoolBatchData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="层次">
          <el-select
            v-model="resForm.eduCategory"
            @change="selectThirdEvent"
            placeholder="请选择层次"
            filterable
            clearable
          >
            <el-option v-for="(item, index, key) in $common.getEduCategory" :key="key" :label="item" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业">
          <el-select
            v-model="resForm.majorId"
            @focus="getScStudentMajorEvent('resForm')"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="item in majorNameData"
              :key="item.majorName"
              :label="item.majorName"
              :value="item.majorId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教学计划">
          <el-input
            v-model="resForm.name"
            placeholder="教学计划"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn_area">
        <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
        <el-button type="info" plain @click="resetEvent">重置</el-button>
      </div>
    </div>
    <div class="table_sList">
      <div class="opera_btnArea">
        <el-button class="mainBtn" @click="moreReplace" plain>{{addType == 'courseware' ? '批量更换课件' : '批量更换教辅材料'}}</el-button>
        <el-button class="mainBtn" @click="moreCancel" plain>{{addType == 'courseware' ? '批量取消课件匹配' : '批量取消教辅材料匹配'}}</el-button>
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%"
        height="430"
        align="center"
        ref="examTable"
        row-key="teachingPlanId"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          :reserve-selection="true"
          width="40"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in columnList"
          :key="index"
          :prop="item.prop"
          :label="item.label ? item.label : '/'"
          :width="item.width"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="scope.column.property == 'name' || scope.column.property == 'coursewareName' || scope.column.property == 'ebookName'">
              <el-tooltip
                :content="getTeachName(scope.row, scope.column.property)"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis" v-if="scope.column.property == 'name'">{{ getTeachName(scope.row, scope.column.property) }}</div>
                <el-button v-else @click="showCourseEvent(scope.row)" type="text" size="small">{{scope.row[scope.column.property] || '暂无'}}</el-button>
              </el-tooltip>
            </template>
            <span v-else-if="scope.row[scope.column.property] == null">/</span>
            <template v-else-if="scope.column.property === 'eduCategory'">
              <span>{{$common.getEduCategory[scope.row[scope.column.property]]}}</span>
            </template>
            <span v-else>
              {{ scope.row[scope.column.property] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
    </div>
    <div class="btn_area">
      <el-button @click="closeEvent">关 闭</el-button>
    </div>

    <handle
      :isShow="isShowDel"
      :handleText="handleText"
      @handlesEvent="doEvent"
    >
      <div class="btn_area" slot="handle_btn">
        <el-button @click="doEvent">关闭</el-button>
        <el-button type="primary" @click="deleStu">确定</el-button>
      </div>
    </handle>
  </div>
</template>

<script>
import publicMethod from '@/components/utils/publicMethod'
import Pagination from "@/components/Pagination"
import handle from "@/components/commonModule/handle"
export default {
  name: 'addResource',
  components: {
    Pagination,
    handle
  },
  data() {
    return {
      resForm: {
        batch: null,
        courseId: null,
        eduCategory: null,
        majorId: null,
        name: null,
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      columnList: [
        {
          prop: "name",
          label: "教学计划名称"
        },
        {
          prop: "admissionBatch",
          label: "批次",
          width: 80
        },
        {
          prop: "eduCategory",
          label: "层次",
          width: 90
        },
        {
          prop: "majorName",
          label: "专业",
          width: 160
        }
      ],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      cancelForm: {
        courseId: null,
        coursewareId: null,
        coursewareName: null,
        ebookIds: null,
        ebookName: null,
        teachPlanIds: null,
        type: null
      },
      schoolBatchData: [],
      majorNameData: [],
      isShowSub: false,
      isShowDel: false,
      handleText: '是否确认批量取消一下教学计划课件匹配？',
      idArr: [],
      addType: null,
      objData: null,
      rowArr: [],
    }
  },
  mounted() {
    this.getGoSchoolData()
  },
  methods: {
    ...publicMethod,
    getTeachName(row, property) {
      if(property == 'name')
        return `${row.admissionBatch || ''}${row.majorName || ''}${this.$common.getEduCategory[row.eduCategory]}`
      else
        return row[property]
    },
    handleSelectionChange(val) {
      let idArr = val.map((item, index) => {
        return item.teachingPlanId
      })
      this.idArr = idArr
      this.rowArr = val
    },
    sendData(obj) {
      this.objData = obj
      this.addType = obj.type
      this.resForm.courseId = obj.courseId
      this.cancelForm.courseId = obj.courseId
      this.cancelForm.type = obj.type == 'courseware' ? 1 : 2
      if(obj.type == 'courseware') {
        this.columnList.push({
          prop: "coursewareName",
          label: "课件名称"
        })
      } else {
        this.columnList.push({
          prop: "ebookName",
          label: "教辅材料名称"
        })
      }
      this.getTableData()
    },
    selectThirdEvent() {
      this.resForm.majorId = null
      this.majorNameData = []
    },
    // 搜索事件
    onSubmit() {
      this.resForm.page = 1
      this.resForm.pageSize = 10
      this.getTableData()
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.resForm, this.$options.data().resForm)
      this.resForm.courseId = this.objData.courseId
    },
    // 获取表格数据
    getTableData() {
      this.$request.teachingPlanCourseList(this.resForm).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.resForm.page = val.current;
      this.resForm.pageSize = val.size;
      this.getTableData();
    },
    closeSub() {
      this.isShowSub = !this.isShowSub
    },
    moreReplace() {
      if(this.idArr.length == 0) {
        this.Warn('请选择教学计划')
        return
      }
      let row = {}
      if(this.addType == 'courseware')
        row.subjectId = this.rowArr[0].subjectId
      else
        row.courseId = this.resForm.courseId
      let key = this.addType == 'courseware' ? 'courseware' : 'ebook'
      let obj = JSON.parse(JSON.stringify(this.cancelForm))
      obj.teachPlanIds = this.idArr
      this.$emit('showCourseEvent', key, row)
      this.$emit('getNewForm', obj)
    },
    doEvent() {
      this.isShowDel = !this.isShowDel
    },
    moreCancel() {
      if(this.idArr.length == 0) {
        this.Warn('请选择教学计划')
        return
      }
      this.doEvent()
    },
    deleStu() {
      let obj = JSON.parse(JSON.stringify(this.cancelForm))
      obj.teachPlanIds = this.idArr
      this.$request.updateTeachingPlan(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || "操作成功")
          this.doEvent()
          this.$refs.examTable.clearSelection();
          this.getTableData()
        }
      })
    },
    closeEvent() {
      this.$emit('dialogEvent')
    },
    showCourseEvent(row) {
      let key = this.addType == 'courseware' ? 'replacecourseware' : 'replaceebook'
      let obj = JSON.parse(JSON.stringify(this.cancelForm))
      obj.teachPlanIds = [row.teachingPlanId]
      obj.coursewareId = row.coursewareId
      obj.coursewareName = row.coursewareName
      this.$emit('showCourseEvent', key, row)
      this.$emit('getNewForm', obj)
    },
  }
}
</script>

<style lang='less' scoped>
  .addResource {
    .search-form {
      display: flex;
      .el-form {
        flex: 4;
      }
      .btn_area {
        flex: 1;
      }
    }
    .table_sList {
      margin-top: 0;
      padding: 0;
    }
    .btn_area {
      text-align: center;
    }
  }
</style>