<template>
  <div class="exportResource">
    <dialogDiyNew :isShow="isShowER" :dialogDiyData="dialogERData"
    @dialogEvent="dialogEREvent">
      <div slot="dialogContent" class="ER_area">
        <div class="e_form e_cont">
          <span>选择导出形式</span>
          <el-radio-group v-model="type">
            <el-radio :label="1">按教学计划导出</el-radio>
            <el-radio :label="2">按课程导出</el-radio>
          </el-radio-group>
        </div>
        <div class="e_area e_cont">
          <span>选择导出范围</span>
          <el-radio-group v-model="allFlag">
            <el-radio :label="true">当前筛选条件课程</el-radio>
            <el-radio :label="false">当前勾选课程</el-radio>
          </el-radio-group>
        </div>
        <div class="btn-area">
          <el-button class="mainBtn" @click="enterEvent">确定</el-button>
          <el-button @click="dialogEREvent">取消</el-button>
        </div>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
import dialogDiyNew from '@/components/commonModule/dialogDiyNew'
export default {
  name: 'exportResource',
  components: {
    dialogDiyNew
  },
  data() {
    return {
      isShowER: false,
      dialogERData: {
        title: "导出资源情况",
        sizeStyle: { width: "450px", height: "250px" }
      },
      type: 1,
      allFlag: true,
      ids: [],
    }
  },
  methods: {
    showEvent(obj) {
      this.ids = obj.ids
      this.type = 1
      this.allFlag = true
      this.dialogEREvent()
    },
    dialogEREvent() {
      this.isShowER = !this.isShowER
    },
    enterEvent() {
      if (!this.allFlag && !this.ids.length) {
        this.Warn('请选择要导出的课程')
        return;
      }
      this.$parent.exportCourseRes({type: this.type, allFlag: this.allFlag})
    }
  }
}
</script>

<style lang="less" scoped>
  .exportResource {
    .ER_area {
      box-sizing: border-box;
      padding: 20px;
      .e_cont {
        margin-bottom: 20px;
        span {
          margin-right: 10px;
        }
      }
      .btn-area {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
</style>